import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HttpResult} from '../entity/http-result';
import {Observable} from 'rxjs';
import {PublicKeyApi} from './public-key.api';
import {BaseApi} from "./base.api";
import {UserProfile} from "../entity/http-vo";

@Injectable({
  providedIn: 'root'
})
export class UserProfileApi extends BaseApi {
  constructor(private http: HttpClient, private publicKeyApi: PublicKeyApi) {
    super()
  }

  getProfile(): Observable<UserProfile> {
    return this.httpResultMap(this.http.get<HttpResult<UserProfile>>(`${environment.BUSHEZHOUYE_API}/v2/user-profile`))
  }

  updateProfile(formData: FormData): Observable<UserProfile> {
    return this.httpResultMap(this.http.post<HttpResult<UserProfile>>(`${environment.BUSHEZHOUYE_API}/v2/user-profile`, formData))
  }


}
